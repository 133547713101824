@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

@layer base {
  body {
    @apply font-sans text-default;
  }
}

@layer components {
  .prose {
    @apply max-w-3xl 
      mx-auto
      prose-h2:text-3xl
      prose-h2:font-important
      prose-h2:mb-8
      prose-h3:text-2xl
      prose-h3:font-important
      prose-h3:mb-8
      prose-h4:text-xl
      prose-h4:font-important
      prose-h4:mb-8
      prose-p:mb-8
      prose-ul:pl-0
      prose-li:list-none
      prose-li:before:content-['/']
      prose-li:before:text-primary
      prose-li:before:font-semibold
      prose-li:before:mr-4;
  }

  .wm-vertical-lr {
    writing-mode: vertical-lr;
    text-orientation: mixed;
  }
}

.prose blockquote {
  @apply relative px-8 lg:px-24 py-4 mb-8 lg:border-none border-primary not-italic font-important font-normal;
}

.prose a {
  @apply underline-offset-4 decoration-primary hover:bg-primary-light visited:text-primary-dark visited:hover:text-primary transition;
}

.prose a.button {
  @apply inline-block py-4 px-10 lg:py-6 lg:px-14 rounded-full border text-sm font-important font-medium uppercase no-underline tracking-wider transition;
}

.prose a.button.button--primary:not(.inverse) {
  @apply border-primary bg-primary text-white hover:border-primary-dark hover:bg-primary-dark;
}

.prose a.button.button--primary.inverse {
  @apply border-primary bg-white text-primary hover:bg-primary hover:text-white;
}

.prose a.button.button--secondary:not(.inverse) {
  @apply border-secondary bg-secondary text-white hover:border-secondary-dark hover:bg-secondary-dark;
}

.prose a.button.button--secondary.inverse {
  @apply border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white;
}

.prose a.button.button--tertiary:not(.inverse) {
  @apply border-tertiary bg-tertiary text-white hover:border-tertiary-dark hover:bg-tertiary-dark;
}

.prose a.button.button--tertiary.inverse {
  @apply border-tertiary bg-transparent text-tertiary hover:bg-tertiary hover:text-white;
}

.prose p:first-child {
  margin-top: 0;
}

.prose p:last-child {
  margin-bottom: 0;
}

.prose-inverse {
  @apply text-white;
}

.prose-inverse a {
  @apply text-white underline underline-offset-4 decoration-primary hover:text-primary visited:text-primary-light visited:hover:text-primary transition-colors;
}
